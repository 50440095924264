<template>
  <Card class="allSearchCard">
    <template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">
            {{cardInfo.qbsAccountName}}
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/male.png"
              v-if="cardInfo.gender === '1'"
            >
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/girl.png"
              v-if="cardInfo.gender === '0'"
            >
            <span
              class="score"
              v-if="cardInfo.score"
            >{{cardInfo.score}}</span>
            <span
              class="over"
              v-if="type === 'followUp' && cardInfo.qbsIsDelay"
            >Overdue</span>
            <span
              class="dormancy"
              v-if="type === 'dormancy' && cardInfo.hibernateday"
            >{{cardInfo.hibernateday}}</span>
          </h3>
          <span class="tag">{{cardInfo.status}}</span>
        </div>
        <div class="description">
          <h4 class="accountMobile">
            <span>
              <Icon name="phone" />{{cardInfo.qbsAccountPhone}}
            </span>
            <span>{{cardInfo.threeLevelChannel}}</span>
          </h4>
          <!-- <span v-if="type === 'contact'"
                class="source-name">{{cardInfo.campaignSourceName}}</span> -->
        </div>
      </template>
      <template>
        <div class="msg-text">
          <span>Latest follow-up:</span>
          <span>{{cardInfo.qbsFollowupTime}}</span>
        </div>
        <div class="msg-text">
          <span>Follow-up details:</span>
          <span>{{cardInfo.qbsFollowupMessage}}</span>
        </div>
      </template>
      <template slot="footer">
        <span class="msg">
          <Icon name="manager" />{{cardInfo.ownerUserName}}
        </span>
        <div class="footer-right">
          <a >
            <img style="width:28px;margin-right:10px;" src="../../../../assets/images/icon/wechat@2x.png">
          </a>
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <img style="width:32px;margin-right:10px;" src="../../../../assets/images/icon/phone@2x.png">
          </a>
          <Button
            round
            type="info"
            size="small"
            @click="userDetail(cardInfo.eosAccountId)"
          >Details</Button>
          <Button
            round
            type="info"
            size="small"
            @click="followUp(cardInfo.eosLeadsId)"
          >Follow up</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from 'vant'
import Card from '_c/card'
import { orgCode } from '@/api/chargequery'
import '@/style/vant-patch.less'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Card,
    Button,
    // CardCell,
    Icon,
    // Row,
    // Cols: Col
  },
  methods: {
    followUp (id) {
      this.$router.push({ path: '/cluesFollowUpFrom', query: { eosLeadsId: id } })
    },
    userDetail (id) {
      this.$router.push({ path: '/customerInfo', query: { id } })
    },
    init () {
      console.log(111)
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.qbsAccountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>